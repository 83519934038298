<template>
  <div class="wrapper">
    <van-nav-bar v-if="preview == '1'"
                 :title="detail.title1"
                 @click-left="onClickLeft">
      <template #left>
        <van-icon color="#333333"
                  name="arrow-left"
                  size="18" />
      </template>
    </van-nav-bar>
    <van-empty v-if="(detail.status == '1' || detail.ac_status == '1' || detail.status == '2') && preview =='0'"
               :image="endImg"
               description="你来晚了，活动已结束" />
    <template v-else>

      <img style="width:100%;height:auto"
           :src="detail.imgs[0]"
           v-if="detail.activity_type == '1' || detail.activity_type == '3'"
           alt="">
      <div class="box"
           :style="{paddingBottom:clientHeight + 'px'}">
        <div class="bc-row-between">
          <div class="title bc-flex-1">
            {{detail.title1}}
          </div>
          <img @click="share"
               v-if="preview == '0'"
               style=" width: 24px;height: 24px;"
               src="@/assets/images/share.png"
               alt="" />
        </div>

        <div class="time">
          活动时间 {{detail.start_time}} - {{detail.end_time}}
        </div>
        <div class="title2">活动详情</div>
        <div class="desc">
          {{detail.desc}}
        </div>
        <div class="imgs">
          <img v-for='(item,index) in  detail.detail_imgs'
               :key='index'
               style="width:100%;height:auto"
               :src="item"
               alt="">
        </div>
        <div v-if="detail.activity_type == '1'"
             class="title2 bc-row-between bc-align-center"
             style="padding:15px 0px 15px 8px;border-bottom: 1px solid #dddddd;margin:0px">
          <div>优惠券适用商品</div>
          <div class="bc-row-between bc-align-center"
               @click='show = true'>
            <span style="color:#808388;font-size: 13px;">查看</span>
            <van-icon color="#808388"
                      name="arrow" />
          </div>
        </div>

        <div class="coupon-info"
             v-if="detail.activity_type == '1'"
             ref='couponInfo'>

          <div class="hongbao">
            <img style="width:100%;height:auto"
                 src="../../assets/images/hb-bg.png"
                 alt="">
            <div class="hb-content">
              <div class="bc-row-center bc-align-center"
                   style="height:12vw">
                <span style="color:#C95A38;font-size:16px;font-weight: 500;">活动满减券</span>
                <van-icon @click='show2 = true'
                          color="#C95A38"
                          name="warning-o" />
              </div>
              <div class="bc-col-center bc-align-center"
                   style="height:20vw">
                <div><span style="font-size: 12px;color: #e94045;font-weight: 500;">￥</span><span style="font-size: 30px;color: #e94045;font-weight: 600;">{{detail.coupon_info[0].face_value}}</span></div>
                <div style="font-size: 10px;color: #e94045;">有效期：{{detail.coupon_info[0].start_time}}-{{detail.coupon_info[0].end_time}}</div>
              </div>
              <div class="bc-row-center bc-align-center"
                   style="height:29vw">
                <div @click='purchase'
                     class="hb-btn">￥{{detail.coupon_info[0].sale_price}} 立即购买</div>
              </div>
            </div>
          </div>
        </div>
        <div style="width:100%;height:120px"
             v-if="detail.activity_type == '4' && preview == '0'"></div>
        <div class="bottom-btn"
             v-if="detail.activity_type == '4' && preview == '0'">
          <div class="b-btn"
               @click='sgin'>我要报名</div>
        </div>

      </div>
      <van-popup v-model="show"
                 closeable
                 round
                 safe-area-inset-bottom
                 position="bottom">
        <div style="font-size: 18px;color: #202122;font-weight: 500;padding:12px">优惠券适用商品</div>
        <div class="product-box">
          <div class="bc-row-start bc-align-center product-item"
               v-for="(item,index) in detail.product_info"
               :key='index'>
            <van-image style="width:40px;height:40px"
                       :src="item.imgs[0]"
                       :error-icon='errImgSrc'>
              <template v-slot:error>
                <img style="width:40px;height:40px;"
                     :src="errImgSrc"
                     alt="">
              </template>
            </van-image>
            <div style="margin-left: 10px;">{{item.product_name}}</div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="show2"
                 :closeable='false'
                 round
                 safe-area-inset-bottom
                 position="center">
        <div class="popup-box"
             v-if='detail.coupon_info[0]'>
          <div style="font-size: 18px;color: #202122;;font-weight: 400;padding:12px;text-align: center;">满{{detail.coupon_info[0].threshold}}减{{detail.coupon_info[0].face_value}}优惠券</div>
          <div style="padding:10px">
            <span style="color: #505255;">适用门店：</span>
            <span>{{detail.org_info.org_name}}</span>
          </div>
          <div style="padding:10px">
            <span style="color: #505255;">门店地址：</span>
            <span>{{detail.org_info.org_address}}</span>
          </div>
          <div style="padding:10px">
            <span style="color: #505255;">适用商品：</span>
            <span>请查看适用商品列表</span>
          </div>
          <div @click='show2 = false'
               style="width: 84px;height: 34px;background: #00a0ac;border-radius: 6px;font-size: 16px;color: #ffffff;text-align: center;line-height:34px;margin: 10px auto 0px auto">
            知道了
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showPopup"
                 position="bottom"
                 closeable
                 round
                 :close-on-click-overlay="true">
        <div class="popup">
          <div class="popup-title">登录</div>
          <div class="logo">
            <img src="@/assets/images/bc.png"
                 alt="">
          </div>
          <div class="popup-form">
            <!-- <div class="popup-form-title">手机号</div> -->
            <input maxlength="11"
                   type="text"
                   class="input"
                   v-model="phone"
                   placeholder="请输入手机号" />
          </div>
          <div class="popup-form">
            <!-- <div class="popup-form-title">验证码</div> -->
            <div class="input-content">
              <input maxlength="6"
                     type="text"
                     class="input"
                     v-model="sms_code"
                     placeholder="请输入验证码" />
              <span :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                    @click="sendMessage('3')">{{ codeBtnStr }}</span>
            </div>
          </div>
          <div style="color: #808388;font-size: 12px;margin-top:10px">未注册的手机号登录后将自动创建宝秤账号</div>
          <div class="popup-button"
               @click="login">登录</div>
        </div>
      </van-popup>
      <van-popup v-model="showSignPopup"
                 position="bottom"
                 closeable
                 round
                 :close-on-click-overlay="true">
        <div class="popup">
          <div class="popup-title">请输入报名信息</div>
          <div class="from">
            <div class="f-item">
              <div class="left">
                <span class="sp1">*</span>
                <span class="sp2">姓名</span>
              </div>
              <div class="right">
                <input v-model='signData.name'
                       type="text"
                       placeholder="请输入您的姓名">
              </div>
            </div>
            <div class="f-item">
              <div class="left">
                <span class="sp1">*</span>
                <span class="sp2">手机号码</span>
              </div>
              <div class="right">
                <input v-model="phone"
                       type="number"
                       placeholder="请输入您的手机号码">
              </div>
            </div>
            <div class="f-item">
              <div class="left">
                <span class="sp1">*</span>
                <span class="sp2">手机验证码</span>
              </div>
              <div class="right">
                <input maxlength="6"
                       v-model="sms_code"
                       type="text"
                       placeholder="请输入您的验证码">
                <span :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                      @click="sendMessage('6')">{{ codeBtnStr }}</span>
              </div>
            </div>
            <div class="f-item">
              <div class="left">
                <span class="sp1">*</span>
                <span class="sp2">所在省份</span>
              </div>
              <div class="right"
                   @click='showPickerPopup = true'>
                <input v-if='signData.product'
                       type="text">
                <span style="color:#808388"
                      v-if='!areaStr'>请选择</span>
                <span v-else>{{areaStr}}</span>
              </div>
            </div>
            <div class="f-item">
              <div class="left">
                <!-- <span class="sp1">*</span> -->
                <span class="sp2">直营产品</span>
              </div>
              <div class="right">
                <input type="text"
                       placeholder="例如：白菜、西瓜">
              </div>
            </div>
          </div>
          <div class="popup-button"
               @click="sign">确认报名</div>
        </div>
      </van-popup>
      <van-popup v-model="showPickerPopup"
                 position="bottom">
        <van-picker title="选择省份"
                    show-toolbar
                    :columns="columns"
                    @cancel="showPickerPopup = false"
                    @confirm="onConfirm" />
      </van-popup>

      <!-- v-if="isWechat" -->
      <van-popup v-model="showSharePopup"
                 position="bottom"
                 round>
        <van-grid :border="false"
                  :column-num="columnNum">
          <van-grid-item text="微信好友"
                         v-if="isWechat"
                         @click="openWechatShare">
            <van-image slot="icon"
                       :width="45"
                       :height="45"
                       :src="require('@/assets/images/wechat.png')" />
          </van-grid-item>
          <van-grid-item text="朋友圈"
                         v-if="isWechat"
                         @click="openWechatShare">
            <van-image slot="icon"
                       :width="45"
                       :height="45"
                       :src="require('@/assets/images/circle.png')" />
          </van-grid-item>
          <van-grid-item text="海报下载"
                         v-if="detail.activity_type == '1'"
                         @click="createPosters">
            <van-image slot="icon"
                       :width="45"
                       :height="45"
                       :src="require('@/assets/images/download.png')" />
          </van-grid-item>
          <van-grid-item text="复制链接"
                         @click="copy">
            <van-image slot="icon"
                       :width="45"
                       :height="45"
                       :src="require('@/assets/images/link.png')" />
          </van-grid-item>
        </van-grid>
        <div class="share-cancel"
             @click="showSharePopup = false">取消</div>
      </van-popup>
      <div class="share-guide"
           v-if="showWechat"
           @click="showWechat = false">
        <img src="@/assets/images/wechat-guide.png"
             alt="下载页背景图" />
        <div class="share-guide-text">
          <p>点击右上角“…”，</p>
          <p>分享给你身边的朋友</p>
        </div>
      </div>
      <div v-if="showPosters"
           class="posters-mask"
           @click="showPosters = false">
        <div v-if="postersHref"
             style="    background: #fff;width: 340px;border-radius: 15px;padding: 15px 10px;">
          <img style="width:100%;height:auto"
               :src="postersHref"
               alt=""
               class="posters-image" />
        </div>
        <div class="posters-button">长按图片保存</div>
      </div>
      <div id="posters"
           class="posters">
        <div class=""
             style="width: 340px;background: #fff;">
          <img :src='detail.imgs[0]'
               style="width: 340px;height: 255px;" />
          <div class="bc-p-col-12">
            <div class="bc-fz-20 bc-f-w-5"
                 style="color:#333">
              {{detail.title1}}
            </div>
            <div class="bc-col-center bc-align-center bc-p-col-30">
              <div class=""
                   style="border: 1px solid #00a0ac;padding: 4rpx;width: 75px, height: 75px">
                <img style="width: 75px;height: 75px"
                     :src="postersQrCode"
                     alt="">
              </div>
              <div class="bc-m-t-5 bc-color-grey"
                   style="font-size: 18rpx;">
                扫码查看活动详情
              </div>
              <div class="bc-fz-14 bc-m-t-20 bc-color-gray"
                   style="color: #000000;position: relative;">
                {{detail.username}}

                <div class="bc-row-center bc-align-center"
                     style="font-size: 8px;color: #fff;width: 30px;height: 12px;background: #ff7816;border-radius: 6px 0px 6px 0px;position: absolute;top: -9px;right: -25px;">
                  推荐人
                </div>
              </div>
            </div>
            <div class="bc-row-center bc-align-center">
              <div style="width: 28px;height: 1px;background: #00a0ac;"></div>
              <img style="width: 40px"
                   mode='widthFix'
                   src="@/assets/images/bc.png" />
              <div style="width: 28px;height: 1px;background: #00a0ac;"></div>
            </div>
          </div>
        </div>
      </div>

      <img @click="toHome"
           v-if="preview == '0'"
           style="
         z-index:99;
          width: 80px;
          height: 80px;
          position: fixed;
          bottom: 220px;
          right: 10px;
          "
           src="@/assets/images/bt.gif"
           alt="" />

    </template>
  </div>
</template>

<script>
import { loginRole } from '@/api/user.js'
import { getSmsCode } from '@/api/hongbao'
import html2canvas from 'html2canvas'
import { getWechatConfig } from '@/api/config'
import QRCode from 'qrcode'
import { getActivityDetail, getRecordActivityDetail, createActivityRecord, createProfitOrder, getAllArea, activitySignUp } from '@/api/poster'
export default {
  components: {},
  props: {},
  data () {
    return {
      endImg: require('@/assets/images/end.png'),
      show: false,
      show2: false,
      showPopup: false,
      showSharePopup: false,
      showWechat: false,
      showSignPopup: false,
      showPickerPopup: false,
      columns: [],
      showPosters: false,
      errImgSrc: require('../../assets/images/err-img.png'),
      clientHeight: 15,
      isLogin: false,
      detail: {
        imgs: [],
        detail_imgs: [],
        coupon_info: [{
          face_value: '',
          start_time: '',
          end_time: ''
        }],
        product_info: [],
        start_time: '',
        end_time: '',
        org_info: {},
        username: ''
      },
      codeBtnStr: '获取验证码',
      interval: 0,
      phone: '',
      sms_code: '',
      loginData: {
        org_id: '',
        phone: '',
        user_code: ''
      },
      preview: '0',
      isWechat: false,
      shareLink: '',
      shareRecordId: '',
      postersQrCode: '',
      signData: {
        mobile: "",//  报名手机号
        sms_code: "",  //验证码
        user_id: "",  //如果登录了就传user_code , 否则为空
        sign_up_user_id: "", //分享人user_code
        name: "", //如果登录了就传用户名称, 否则为空
        activity_name: "", //活动名称
        activity_id: "",  //活动id
        address: "",  //省份名称
        address_code: "", //省份code
        product: "" //主营行业名称
      },
      areaStr: '',
      areas: [],
      postersHref: ''
    };
  },
  watch: {},
  computed: {
    columnNum () {
      if (this.isWechat && this.detail.activity_type == '1') {
        return 4
      } else if (!this.isWechat && this.detail.activity_type == '1') {
        return 2
      } else if (this.isWechat && this.detail.activity_type != '1') {
        return 3
      } else if (!this.isWechat && this.detail.activity_type != '1') {
        return 1
      }
    }
  },
  methods: {
    onClickLeft () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    },
    onConfirm (value, index) {
      // value, index
      this.showPickerPopup = false
      this.signData.address = value
      this.signData.address_code = this.areas[index].code
      this.areaStr = value
      console.log(value, index)
    },
    sgin () {
      if (this.preview == '1') {
        this.$toast('预览中，不可操作')
        return
      }
      this.showSignPopup = true
    },
    sign () {
      if (!this.signData.name) {
        this.$toast('请输入姓名')
        return
      }

      if (!this.phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!this.sms_code) {
        this.$toast('请输入验证码')
        return
      }
      if (!this.areaStr) {
        this.$toast('请选择省份')
        return
      }
      this.signData.mobile = this.phone
      this.signData.sms_code = this.sms_code
      this.signData.user_id = this.loginData.user_code ? this.loginData.user_code : ''
      this.signData.sign_up_user_id = this.detail.user_id ? this.detail.user_id : ''
      this.signData.activity_name = this.detail.title1
      this.signData.activity_id = this.$route.query.activity_id
      console.log(this.signData)
      activitySignUp(this.signData).then(res => {
        console.log(res)
        this.showSignPopup = false
        this.$toast('报名成功')
        this.signData = {
          mobile: "",//  报名手机号
          sms_code: "",  //验证码
          user_id: "",  //如果登录了就传user_code , 否则为空
          sign_up_user_id: "", //分享人user_code
          name: "", //如果登录了就传用户名称, 否则为空
          activity_name: "", //活动名称
          activity_id: "",  //活动id
          address: "",  //省份名称
          address_code: "", //省份code
          product: "" //主营行业名称
        }
      }).catch(err => {
        this.$toast(err.msg)
      })

    },
    _getActivityDetail () {
      if (this.$route.query.isUniapp) {
        getActivityDetail({
          activity_id: this.$route.query.activity_id
        }).then(res => {
          console.log(res)
          this.detail = res
          document.title = this.detail.title1
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.$refs.couponInfo) {
                this.clientHeight = this.$refs.couponInfo.clientHeight + 20
              }
            }, 100)
          })
          if (localStorage.getItem('user_info')) {
            this.isLogin = true;
            const user_info = JSON.parse(localStorage.getItem('user_info'));
            this.loginData = {
              org_id: user_info.org_id,
              phone: user_info.mobile,
              user_code: user_info.user_code
            }
            this._createActivityRecord()
          }
        })
      } else {
        getRecordActivityDetail({
          record_id: this.$route.query.record_id
        }).then(res => {
          console.log(res)
          this.detail = res.profit_activity
          document.title = this.detail.title1
          this.detail.username = res.username
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.$refs.couponInfo) {
                this.clientHeight = this.$refs.couponInfo.clientHeight + 20
              }
            }, 100)
          })
          if (localStorage.getItem('user_info')) {
            this.isLogin = true;
            const user_info = JSON.parse(localStorage.getItem('user_info'));
            this.loginData = {
              org_id: user_info.org_id,
              phone: user_info.mobile,
              user_code: user_info.user_code
            }
            this._createActivityRecord()
          }

        })
      }
    },

    // 跳转主页
    toHome () {
      if (this.preview == '1') {
        this.$toast('预览中，不可操作')
        return
      }
      if (!this.isLogin) {
        this.showPopup = true
        return
      }
      this.$router.push({
        path: '/articleHome',
        query: {
          source: 'poster',
          org_id: this.loginData.org_id ? this.loginData.org_id : '',
          phone: this.loginData.mobile ? this.loginData.mobile : '',
          user_code: this.loginData.user_code ? this.loginData.user_code : ''
        }
      })
    },
    share () {
      if (this.preview == '1') {
        this.$toast('预览中，不可操作')
        return
      }
      if (!this.isLogin) {
        this.showPopup = true
        return
      }
      this.showSharePopup = true
    },
    openWechatShare () {
      this.showSharePopup = false
      this.showWechat = true
    },
    createPosters () {

      this.$nextTick(() => {
        html2canvas(document.getElementById('posters'), {
          useCORS: true,
          allowTaint: false,
          proxy: this.detail.imgs[0],
          scale: window.devicePixelRatio, // 放大倍数，消除截图锯齿
          width: document.getElementById('posters')?.offsetWidth, // 生成的图片宽度，单位px
          height: document.getElementById('posters').offsetHeight, // 生成的图片高度，单位px
          backgroundColor: 'rgba(0,0,0,0)'
        }).then((canvas) => {
          const img = canvas.toDataURL('image/png')
          this.postersHref = img
          this.showPosters = true
          console.log(img)
        })
      })
    },
    saveCanvas () {
      let base64 = this.postersHref
      var arr = base64.split(',');
      var bytes = atob(arr[1]);
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: 'application/octet-stream' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = new Date().valueOf() + ".png";
      var e = document.createEvent('MouseEvents');
      e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
      URL.revokeObjectURL(url);
      this.showPosters = false
      this.showSharePopup = false
      this.$toast('已保存')

    },
    copy () {
      this.$copyText(this.shareLink)
      this.showSharePopup = false
      this.$toast('复制成功，快去分享吧')
    },
    purchase () {
      if (this.preview == '1') {
        this.$toast('预览中，不可操作')
        return
      }
      if (!this.isLogin) {
        this.showPopup = true
        return
      }
      createProfitOrder({
        record_id: this.$route.query.record_id,
        user_code: this.loginData.user_code,
        sale_price: this.detail.coupon_info[0].sale_price
      }).then(order_code => {
        var host = window.location.host
        console.log(host)
        let url = 'http://demo.suzi.com.cn/i/#'
        let shareUrl = 'http://demo.suzi.com.cn'
        let params = ''
        if (host === 'demo.suzi.com.cn') {
          url = 'http://demo.suzi.com.cn/i/#' // 测试域名
          shareUrl = 'http://demo.suzi.com.cn' // 测试域名
        } else if (host === 'i.suzi.com.cn') {
          url = 'http://i.suzi.com.cn/#' // 正式域名
          shareUrl = 'https://app.suzi.com.cn' // 正式域名
        } else if (host === 'release.suzi.com.cn') {
          url = 'http://release.suzi.com.cn/i/#' // 正式域名
          shareUrl = 'http://release.suzi.com.cn' // 正式域名
        }
        console.log(url)
        let link = `${url}/pay?code=${order_code}&phone=${this.loginData.mobile}&amount=${this.detail.coupon_info[0].sale_price}&user_code=${this.loginData.user_code}&org_id=${this.loginData.org_id}&receipt_org_id=${this.detail.org_id}&appid=wxe3e0ce6ec9255a63&payType=posterOrder&payment_type=29&remark=海报订单`
        const shareLink = `${shareUrl}/auth?back_url=${encodeURIComponent(link)}`
        location.href = shareLink
        // this.$router.push({
        //   path: '/pay',
        //   query: {
        //     open_id: this.$route.query.open_id ? this.$route.query.open_id : '',
        //     code: order_code,
        //     phone: this.loginData.mobile,
        //     amount: `${this.detail.coupon_info[0].sale_price}`,
        //     user_code: this.loginData.user_code,
        //     org_id: this.loginData.org_id,
        //     receipt_org_id: this.detail.org_id,
        //     appid: 'wxe3e0ce6ec9255a63',
        //     payType: 'posterOrder',
        //     payment_type: '29',
        //     remark: '海报订单'
        //   }
        // })
      }).catch(err => {
        this.$toast(err.msg)
      })

    },
    _createActivityRecord (showSharePopup) {
      createActivityRecord({
        record_id: this.$route.query.record_id,
        activity_id: this.$route.query.activity_id,
        user_code: this.loginData.user_code,
      }).then(res2 => {
        console.log(res2)
        this.shareRecordId = res2
        var host = window.location.host
        let url = 'http://demo.suzi.com.cn/i/#/'
        if (host === 'demo.suzi.com.cn') {
          url = 'http://demo.suzi.com.cn/i/#/' // 测试域名
        } else if (host === 'i.suzi.com.cn') {
          url = 'http://i.suzi.com.cn/#/' // 正式域名
        } else if (host === 'release.suzi.com.cn') {
          url = 'http://release.suzi.com.cn/i/#/' // 正式域名
        }
        this.shareLink = `${url}posterDetail?activity_id=${this.$route.query.activity_id}&record_id=${this.shareRecordId}`
        this._getWechatConfig()
        // 生成链接二维码
        QRCode.toDataURL(this.shareLink, { rendererOpts: { quality: 1 } }).then(
          (result) => {
            this.postersQrCode = result
            console.log(this.postersQrCode)
          }
        )
        console.log(this.shareLink)
      })
    },
    login () {
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      loginRole({
        mobile: this.phone,
        sms_code: this.sms_code,
        current_role: '2'
      }).then(res => {
        loading.close()
        console.log(res)
        this.loginData = res.data.data
        this.showPopup = false
        this.isLogin = true
        window.localStorage.setItem('user_info', JSON.stringify(res.data.data))
        this.phone = ''
        this.sms_code = ''
        this._createActivityRecord()
        console.log(res)
      }).catch(err => {
        loading.close()
        console.log(err)
        this.$toast(err.msg)
      })
    },
    sendMessage (template_id) {
      if (this.interval > 0) {
        return
      }
      getSmsCode({
        phone_number: this.phone,
        template_id: template_id

      })
        .then((res) => {
          this.interval = 60
          this.timer()
        })
        .catch((err) => {
          this.$toast(err.msg)
        })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    _getWechatConfig () {
      // 获取微信配置
      getWechatConfig({
        url: location.href.split('#')[0]
      }).then((config) => {
        this.configData = config
        setTimeout(() => {
          this.setConfig(config)
        }, 10)
      })
    },
    setConfig (config) {
      console.log('设置分享')
      console.log("shareLink:" + this.shareLink)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wxe3e0ce6ec9255a63', // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
      })

      wx.ready(() => {
        // 需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: this.detail.title1, // 分享标题
          desc: '活动火热进行中，更多精彩活动等你来参加，快邀请您的好友一起参加吧', // 分享描述
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.detail.imgs[0], // 分享图标
          success: () => {
            console.log()
            console.log('分享成功')
            // alert('配置分享链接成功', 'updateAppMessageShareData')
            // this.showShareSuccessDialog = true
            // this.showRecordEntry = true
          },
          cancel: () => {
            console.log('配置分享链接失败')
            // 用户取消分享后执行的回调函数
            // alertMsg('取消分享')
          }
        })

        wx.updateTimelineShareData({
          title: this.detail.title1, // 分享标题
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.detail.imgs[0], // 分享图标
          success: () => {
            // alert('配置分享链接成功', 'updateTimelineShareData')
            console.log('分享成功')
            // this.showShareSuccessDialog = true
            // this.showRecordEntry = true
          },
          cancel: () => {
            console.log('配置分享链接失败')
            // alert('配置分享链接失败')
            // 用户取消分享后执行的回调函数
            // alertMsg('取消分享')
          }
        })

        wx.onMenuShareTimeline({
          title: this.detail.title1, // 分享标题
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.detail.imgs[0], // 分享图标
          success: () => {
            console.log('分享成功')
          },
          cancel: () => {
            console.log('配置分享链接失败')
            // alert('配置分享链接失败')
            // 用户取消分享后执行的回调函数
            // alertMsg('取消分享')
          }
        })

        wx.onMenuShareAppMessage({
          title: this.detail.title1, // 分享标题
          desc: '活动火热进行中，更多精彩活动等你来参加，快邀请您的好友一起参加吧', // 分享描述
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.detail.imgs[0], // 分享图标
          success: () => {
            console.log('分享成功')
          },
          cancel: () => {
            console.log('配置分享链接失败')
            // alert('配置分享链接失败')
            // 用户取消分享后执行的回调函数
            // alertMsg('取消分享')
          }
        })

        wx.error(function (res) {
          alert(JSON.stringify(res) + '>>>>>>>失败')
        })
      })
    },
    _getAllArea () {
      getAllArea({
        region_level: '1'
      }).then(res => {
        console.log(res)
        this.columns = res.data.data.map(item => {
          return item.name
        })
        this.areas = res.data.data
      })
    }
  },
  mounted () {

    //  console.log(process.env)
    this.isWechat = this.$isWechat()
    this.preview = this.$route.query.preview ? this.$route.query.preview : '0'
    this._getActivityDetail()
    console.log(localStorage.getItem('user_info'))
    console.log(this.isLogin)
    this._getAllArea()
  },

};
</script>
<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  height: 50px;
  position: fixed;
  background: #fff;
  bottom: 0px;
  left: 0px;
  padding: 5px;
  box-sizing: border-box;
  .b-btn {
    width: 100%;
    height: 40px;
    background: #00a0ac;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 40px;
  }
}
.posters-button {
  background: #00bc86;
  padding: 6px 15px;
  border-radius: 60px;
  font-size: 14px;
  margin-top: 20px;
}
.posters-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.posters {
  position: fixed;
  top: -1000px;
  background: #fff;
  width: 340px;
  height: 510px;
  border-radius: 15px;
  padding: 15px 10px;

  .posters-cover {
    width: 100%;
    height: 170px;
    border-radius: 15px 15px 0 0;
    // object-fit: cover;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .posters-title {
    font-size: 14px;
    color: #202122;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px dashed #eee;
  }

  .posters-footer {
    font-size: 12px;
    color: #808080;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .posters-qr-code {
    height: 80px;
    width: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
.share-guide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba($color: #000000, $alpha: 0.6);
  // padding-top: 150px;
  color: #fff;

  img {
    width: 130px;
    position: absolute;
    right: 25px;
    top: 5px;
  }

  .share-guide-text {
    position: absolute;
    top: 160px;
    text-align: center;
    right: 70px;
    line-height: 1.8;
    font-size: 16px;
  }
}
.share-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 14px;
  border-top: 1px solid #eee;
}
.box {
  padding: 15px;
  .title {
    width: 0px;
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    color: #202122;
    word-wrap: break-word;
    padding-right: 20px;
  }
  .time {
    font-size: 12px;
    color: #202122;
    margin-top: 8px;
  }
  .title2 {
    font-size: 16px;
    font-weight: 500;
    color: #202122;
    position: relative;
    padding-left: 6px;
    margin-top: 20px;
    // border-left: 2px solid #f7595d;
  }
  .title2::after {
    content: "";
    width: 2px;
    height: 14px;
    background: #f7595d;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -7px;
  }
  .desc {
    margin-top: 10px;
    font-size: 14px;
    color: #505255;
    font-weight: 500;
    line-height: 26px;
  }
  .coupon-info {
    position: fixed;
    width: 100%;
    bottom: 0px;
    background: #fff;
    left: 0px;
    padding: 0px 15px;
    box-sizing: border-box;
  }
  .hongbao {
    margin: 20px 0px;
    position: relative;
    .hb-content {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
  .hb-btn {
    width: 294px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: linear-gradient(180deg, #ffebd2, #f0bb7f 100%);
    border-radius: 23px;
    font-size: 18px;
    color: #e02b31;
    font-weight: 600;
  }
}
.product-box {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  .product-item {
    padding: 0px 12px;
    height: 70px;
    border-bottom: 0.5px solid #dddddd;
  }
}
.popup-box {
  width: 308px;
  padding-bottom: 20px;
  // height: 213px;
  background: #ffffff;
  border-radius: 10px;
}
.popup {
  padding: 0 20px;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    .img {
      width: 80px;
      height: 80px;
      background: red;
    }
  }
  .popup-title {
    padding: 20px 0px 0px 0px;
    font-size: 16px;
  }
  .from {
    .f-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0px;
      .left {
        width: 100px;
        display: flex;
        align-items: center;
        .sp1 {
          color: #fa675c;
        }
        .sp2 {
          color: #505255;
          font-size: 16px;
          margin-left: 4px;
        }
      }
      .right {
        flex: 1;
        height: 30px;
        background: #ffffff;
        border: 1px solid #00a0ac;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        overflow: hidden;
        input {
          flex: 1;
          width: 0px;
          height: 28px;
          border: none;
          outline: none;
          padding: 8px 0;
          color: #202122;
        }
      }
    }
  }

  .popup-form {
    border-bottom: 1px #ddd solid;
    padding: 10px 0px;
    margin-bottom: 10px;

    .input {
      border: none;
      outline: none;
      padding: 8px 0;
      color: #202122;
    }

    .popup-form-title {
      color: #505255;
      font-size: 13px;
    }
  }

  .input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00a0ac;
  }

  .popup-button {
    border-radius: 4px;
    background: #00a0ac;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 70px 0 20px;
  }
}
</style>
