<template>
  <div class="wrapper">

    <!-- 搜索 -->
    <div class="search-bar top-search" v-if="isSearch">
      <img src="@/assets/images/back_black.png" class="navbar_back" @click="clearSearch()" />
      <van-search v-model="search_data" ref="searchInput" show-action placeholder="请输入商品名称" @search="search" style="flex: 1;padding-left: 0;">
        <template #action>
          <div @click="getList">搜索</div>
        </template>
      </van-search>
    </div>
    <van-search v-else v-model="search_data" class="top-search" :disabled="true" placeholder="搜索" @click="search()" />

    <template v-if="!isSearch">
      <!-- 照片墙 -->
      <div class="swipe-box" style="height: 200px" v-if="orgInfo.pic && orgInfo.pic.length">
        <van-swipe style="height: 200px" :show-indicators='false' class="swipe" @change="onChange"
          indicator-color="white">
          <van-swipe-item v-for='(item, index) in  orgInfo.pic' :key='index'>
            <van-image height="200px" width="100%" fit="cover" :src="item" :error-icon='errImgSrc'>
            </van-image>
          </van-swipe-item>
        </van-swipe>
        <div class="indicator" v-if='orgInfo.pic.length'>
          {{ current + 1 }}/{{ orgInfo.pic.length }}
        </div>
      </div>

      <!-- 店铺信息 -->
      <div class="store-msg">
        <div class="store-name">
          {{ orgInfo.org_name }}
        </div>

        <div class="store-classify">
          经营：{{ orgInfo.main_sales }}
        </div>

        <div class="store-address">
          <div>
            <img src="../../assets/auth/address-1.png" style="width: 16px;height:16px;" alt="">
            {{ orgInfo.address }}
          </div>
          <a class="flex-col" :href='`tel:${orgInfo.contacts_phone}`'>
            <img src="../../assets/auth/phone.png" style="width: 20px;height:20px;" alt="">
            <span style="font-size: 10px; color: #666666;">电联</span>
          </a>
        </div>
        <div class="store-sign">
          <img v-for='(item, index) in authBottom' :key='index' :src="item.icon" mode="heightFix" />
        </div>
      </div>
    </template>

    <div v-if="publicList.length && !isSearch" class="title">全部商品</div>
    <van-empty v-if='isEmpty' description="暂无商品" />
    <div class="product">
      <div v-for="(item, index) in publicList" :key="index" class="product-item" @click="toPublicProduct(item)">
        <van-image style="width:118px;height:118px" :src="item.compress_img || item.img" :error-icon='errImgSrc'>
        </van-image>
        <div class="product-content">
          <div>
            <div class="bc-line-2">
              {{ item.product_name }}
            </div>
            <div class="specs ">{{ item.unit_docs }}</div>

          </div>
          <div class="bc-row-start bc-align-center">
            <div class="">
              <span class="bc-color-price">￥</span>
              <span class="bc-color-price">{{ item.price }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="publicList.length" style="padding:20px 0px;text-align: center;">没有更多数据了</div>
    </div>
  </div>
</template>

<script>
import { orgDetail, getProductList } from '@/api/public.js'
export default {
  components: {},
  props: {},
  data () {
    return {
      isSearch: false,
      search_data: '',
      current: 0,
      clientWidth: '',
      isEmpty: false,
      orgInfo: {},
      errImgSrc: require('../../assets/images/err-img.png'),
      publicList: [],
      authBottom: [],
      authBottomTags: [{
        id: '102',
        name: '实名认证',
        icon: require('../../assets/auth/smrz.png'),
        height: '4.3vw',
        width: 16
      },

      {
        id: '101',
        name: '营业执照',
        icon: require('../../assets/auth/yyzz.png'),
        height: '4.3vw',
        width: 16
      },
      {
        id: '103',
        name: '特许经营资质',
        icon: require('../../assets/auth/txjyzz.png'),
        height: '4.3vw',
        width: 16
      },
      {
        id: '108',
        name: '信用等级',
        height: '4.3vw',
        icon: [
          require('../../assets/auth/xydj1.png'),
          require('../../assets/auth/xydj2.png'),
          require('../../assets/auth/xydj3.png'),
          require('../../assets/auth/xydj4.png'),
          require('../../assets/auth/xydj5.png')
        ]
      }
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {
    clearSearch () {
      this.isSearch = false
      this.search_data = ''
      this.getList()
    },
    search () {
      this.isSearch = true
      this.publicList = []
      this.$nextTick(() => {
        // console.log(this.$refs.searchInput)
        // console.log(this.$refs.searchInput.querySelector('input'))
        this.$refs.searchInput.querySelector('input').focus()
      })
    },
    onChange (index) {
      this.current = index
    },
    toPublicProduct (item) {
      this.$router.push({
        path: '/publicProductDetail',
        query: {
          product_id: item.product_id,
          org_id: this.$route.query.org_id,
          unit_id: item.unit_id
        }
      })
    },
    setSign () {
      this.authBottomTags.forEach((item) => {
        const id = item.id
        const tag = this.orgInfo.authentications[id]
        if (tag) {
          if (id === '108') {
            this.authBottom.push({
              icon: item.icon[tag.level.length - 1],
              height: item.height
            })
          } else {
            this.authBottom.push({
              icon: item.icon,
              height: item.height
            })
          }
        }
      })
    },
    getList () {
      getProductList({
        classify_id: '',
        org_id: this.$route.query.org_id,
        page: 1,
        per_page: 10000,
        preview: '',
        search_data: this.search_data
      }).then(res => {
        this.publicList = res
        this.isEmpty = res.length === 0
      })
    }
  },
  created () {
    document.title = '店铺详情'
    this.clientWidth = `${document.documentElement.clientWidth}px`

    orgDetail({
      org_id: this.$route.query.org_id
    }).then(res => {
      console.log(res)
      this.orgInfo = res

      this.setSign()
      this.getList()
    })
  },
  mounted () { }
}
</script>
<style lang="scss" scoped>
.search-bar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw;
  box-sizing: border-box;
}
.navbar_back {
  height: 4.8vw;
  width: 4.8vw;
}

.top-search {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 9999;
}

.swipe-box {
  width: 100%;
  position: relative;
  height: 200px;
  margin-bottom: 10px;

}

.swipe {
  width: 100%;
}

.indicator {
  padding: 3px 8px;
  line-height: 1;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.follow-button {
  width: 48px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
  background: #202122;
  opacity: .5;

  &.active {
    background: #FF6600;
    opacity: 1;
  }
}

.wrapper {
  width: 100%;
  min-height: 100%;
  background: #f1f1f1;

  .address {
    color: #333333;
    font-size: 12px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .org-name {
    font-size: 18px;
    padding: 15px;
    font-weight: 800;
  }

  .store-msg {
    padding: 16px 15px 15px 15px;
    position: relative;
    background: #fff;
    margin-bottom: 10px;

    .store-name {
      font-size: 18px;
      font-weight: 600;
    }

    .store-classify {
      color: gray;
      font-size: 12px;
      padding: 6px 0;
    }

    .store-address {
      color: gray;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .store-sign {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 10px;

      img {
        margin-right: 5px;
        height: 16px;
      }
    }
  }

  .title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #fff;
    // border-top-right-radius: 20px;
    // border-top-left-radius: 20px;
    font-weight: 500;
    color: #00a0ac;
    font-size: 18px;
    position: sticky;
    top: 50px;
    width: 100%;
    z-index: 9999;
  }

  .product {
    background: #fff;
  }

  .product-item {
    height: 118px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    margin-bottom: 15px;

    .specs {
      display: inline-block;
      background: #f3f3f3;
      color: #999999;
      font-size: 12px;
      line-height: 1;
      padding: 2px 5px;
      border-radius: 2px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .product-content {
      flex: 1;
      height: 100%;
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
