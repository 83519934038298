<!-- 物流单详情 -->
<template>
  <div>
    <!-- 头部 -->
    <div class="navbar">
      <img
        src="@/assets/images/back_black.png"
        class="navbar_back"
        @click="toBack"
      />
      <span class="navbar_title">物流单详情</span>
      <span></span>
    </div>

    <div class="content">
      <!-- 物流单状态: 0待接单 1已拒单 2待揽货 3运输中 4已签收 5已完成 -->
      <div class="status">{{ detailForm.express_status_text }}</div>
      <!-- 送货单明细 -->
      <div class="list">
        <div class="title">送货单明细</div>
        <div class="detail">
          <div class="item">
            <span class="label">送货单号</span>
            <span>{{ detailForm.third_id }}</span>
          </div>
          <div class="item">
            <span class="label">包裹数量</span>
            <span>{{ detailForm.package_qty }}</span>
          </div>
          <div class="item">
            <span class="label">总重量</span>
            <span>{{ detailForm.package_weight }}</span>
          </div>
          <div class="item">
            <span class="label">总体积</span>
            <span>{{ detailForm.package_volume }}</span>
          </div>
          <div class="item">
            <span class="label">备注</span>
            <span>{{ detailForm.package_remark }}</span>
          </div>
          <div class="item" style="align-items: flex-start">
            <span class="label">发货人</span>
            <div>
              <div>
                <span style="margin-right: 6px">
                  {{ send_address.name }}
                </span>
                <span>{{ send_address.phone }}</span>
                <img src="@/assets/images/phone.png" class="icon-phone" alt @click="copy(detailForm.send_address.phone)" />
              </div>
              <div>{{ send_address.address }}</div>
            </div>
          </div>
          <div class="item" style="align-items: flex-start">
            <span class="label">收货人</span>
            <div>
              <div>
                <span style="margin-right: 6px">
                  {{ order_address.name }}
                </span>
                <span>{{ order_address.phone }}</span>
                <img src="@/assets/images/phone.png" class="icon-phone" alt @click="copy(detailForm.order_address.phone)" />
              </div>
              <div>{{ order_address.address }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 物流单明细 -->
      <div class="list">
        <div class="title">物流单明细</div>
        <div class="detail">
          <div class="item">
            <span class="label">物流单号</span>
            <span>{{ detailForm.express_id }}</span>
          </div>
          <div class="item">
            <span class="label">服务商</span>
            <div>
              <span>{{ detailForm.express_org_name }}</span>
              <img src="@/assets/images/phone.png" class="icon-phone" alt  @click="copy(detailForm.express_phone)" />
            </div>
          </div>
          <div class="item">
            <span class="label">运费</span>
            <span>￥{{ detailForm.total_amount }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部操作按钮 -->
    <div class="footer">
        <span class="btn" v-if="detailForm.express_status === '2'" @click="openFreightConfirm()">确认揽货</span>

        <span class="btn" v-if="['3'].includes(detailForm.express_status) && detailForm.sign_order_confirm == '0'" @click="openExpressConfirm()">确认送达</span>

        <span class="btn" v-if="['4'].includes(detailForm.express_status)" @click="openCollectionDialog()">收取运费</span>

        <span class="btn" v-if="detailForm.unpaid_amount !== '0.00' && detailForm.sign_express_confirm === '1'" @click="openCode()">代收货款</span>
    </div>

    <!-- 收货款-弹窗 -->
    <van-dialog v-model="showCode" :width="300" :show-confirm-button="false" closeOnClickOverlay>
      <div class="qr-code">
        <img :src="qrCodeImage" alt="" class="dialog-code" />
        <p class="code-title">代收货款 <span class="code-price">￥{{this.detailForm.unpaid_amount}}</span></p>
      </div>
    </van-dialog>
    <!-- 揽货 -->
    <freight-dialog v-model="showFreightConfirmDialog" :list="freightList" @success="_expressOrderDetail"></freight-dialog>

    <!-- 送达 -->
    <express-dialog v-model="showExpressConfirmDialog" :list="expressList" @success="_expressOrderDetail"></express-dialog>

    <!-- 收取运费 -->
    <collection-dialog
      v-model="showCollectionConfirmDialog"
      :list="collectionList"
      :amount="re_amount"
      @success="_expressOrderDetail"
    ></collection-dialog>
  </div>
</template>

<script>
import { expressOrderDetail } from '@/api/public'
import freightDialog from './views/freight-dialog.vue'
import expressDialog from './views/express-dialog.vue'
import collectionDialog from './views/collection-dialog.vue'
import QRCode from 'qrcode'

export default {
  components: {
    freightDialog,
    expressDialog,
    collectionDialog
  },
  data () {
    return {
      detailForm: {},
      send_address: {},
      order_address: {},
      freightList: [],
      expressList: [],
      collectionList: [],
      re_amount: '',
      showFreightConfirmDialog: false,
      showExpressConfirmDialog: false,
      showCollectionConfirmDialog: false,
      showCode: false,
      qrCodeImage: ''

    }
  },
  created () {
    this._expressOrderDetail()
  },
  methods: {
    toBack () {
      window.history.back()
    },
    // 复制
    copy (data) {
      this.$copyText(data)
      this.$toast('复制成功')
    },
    // 获取物流单详情
    _expressOrderDetail () {
      expressOrderDetail({
        // express_id: 'E2021093017341962974'
        express_id: this.$route.query.express_id
      })
        .then((res) => {
          this.detailForm = res
          this.order_address = res.order_address
          this.send_address = res.send_address
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
          this.$toast(err.msg || '获取物流单详情失败')
        })
    },
    // 确认揽货
    openFreightConfirm () {
      this.freightList = [{
        express_id: this.detailForm.express_id,
        user_code: this.detailForm.express_user_code,
        org_id: this.detailForm.express_org_id
      }]
      this.showFreightConfirmDialog = true
    },
    // 确认送达
    openExpressConfirm () {
      this.expressList = [this.detailForm]
      this.showExpressConfirmDialog = true
    },
    // 收取运费
    openCollectionDialog () {
      this.re_amount = this.detailForm.re_amount
      this.collectionList = [this.detailForm]
      this.showCollectionConfirmDialog = true
    },
    // 代收货款
    openCode () {
      QRCode.toDataURL(JSON.stringify({
        params_type: 'order_pay',
        params: {
          order_code: this.detailForm.order_code,
          amount: this.detailForm.unpaid_amount,
          order_seller_org_id: this.detailForm.order_org_id
        }
      }), { rendererOpts: { quality: 1 } }).then(
        (result) => {
          this.qrCodeImage = result
        }
      )
      this.showCode = true
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid rgb(235, 231, 231);
  z-index: 9;
}

.content {
  min-height: 100vh;
  box-sizing: border-box;
  background: #f3f4f6;
  color: #202122;
}
.status {
  padding: 0 16px;
  color: #56bd48;
  background-color: #fff;
  font-size: 16px;
  height: 44px;
  line-height: 44px;
}

.list {
  margin-top: 10px;
  background-color: #fff;

  .title {
    border-bottom: 1px solid rgb(235, 231, 231);
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 16px;
  }
  .detail {
    padding: 4px 0;
    padding-bottom: 10px;
    .item {
      font-size: 12px;
      padding: 4px 16px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      .label {
        min-width: 66px;
        flex-shrink: 0;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 50px;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn {
    width: 99px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 0.5px solid #a3a3a3;
    border-radius: 4px;
    margin-left: 10px;
    color: #505255;
    font-size: 14px;
  }
}
.icon-phone {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-top: -2px;
}

.qr-code {
  text-align: center;
  padding: 20px;

  .dialog-code {
    width: 185px;
    height: 185px;
    margin-bottom: 22px;
  }

  .code-title {
    color: #505255;
    font-size: 16px;

    .code-price {
      color: #fa675c;
      margin-left: 6px;
    }
  }
}
</style>
