import { app } from "@/plugins/appAxios";
import { api } from "@/plugins/axios";
import { mangeApi } from "@/plugins/mangeAxios";

// 获取文章详情
export function getActivityDetail(params) {
  return app("/profit_activity/activity_detail", params);
}

export function getRecordActivityDetail(params) {
  return app("/profit_activity/get_activity", params);
}

export function createActivityRecord(params) {
  return app("/profit_activity/create_activity_record", params);
}

export function createProfitOrder(params) {
  return app("/profit/create_profit_order", params);
}

export function getAllArea(params) {
  return api("/area/all_area", params);
}

export function activitySignUp(params) {
  return app("/profit_activity/activity_sign_up", params);
}
