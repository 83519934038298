import { render, staticRenderFns } from "./publicProductDetail.vue?vue&type=template&id=0bbddc70&scoped=true&"
import script from "./publicProductDetail.vue?vue&type=script&lang=js&"
export * from "./publicProductDetail.vue?vue&type=script&lang=js&"
import style0 from "./publicProductDetail.vue?vue&type=style&index=0&id=0bbddc70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbddc70",
  null
  
)

export default component.exports